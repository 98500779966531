import axios from '@axios'
import { db, auth, storage } from '@/Firebase/Firebase.service'
import { collectionGroup, collection, getDocs, getDoc, doc, addDoc, where, query, setDoc } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";
import { deleteUser } from "firebase/auth";



export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    DeleteAccount(userId) {
      return new Promise(async (resolve, reject) => {
        try {
          console.log('deleting user ' + userId);

          //get data needed for full account deletion
          var accountData = ref(JSON.parse(localStorage.getItem('userData')))
          const userDocRef = doc(db, "users", accountData.value.id)



          //delete my membership documents
          const membershipsQuery = query(collectionGroup(db, 'memberships'), where('user', '==', userDocRef))
          var membershipDocs = await getDocs(membershipsQuery)
          membershipDocs.forEach(membershipDoc => {
            //delete docs
            console.log('deleting membership' + membershipDoc)
            //deleteDoc(membershipDoc.ref)
          })

          //delete my user document
          console.log('deleting user document')
          //await deleteDoc(userDocRef)
          

          //change uploadedby in projects
          const userProjects = query(collectionGroup(db, 'projects'), where('uploadedBy', '==', userDocRef))
          var userProjectsDocs = await getDocs(userProjects)
          console.log("got all user projects")

          const unkownuserDocRed = doc(db, 'users', 'unkownuser')


          userProjectsDocs.forEach(projectDoc => {
            //change to null user
            console.log('changing to null user ' + projectDoc);
            //updateDoc(projectDoc.ref, { uploadedBy: unkownuserDocRed})
            



          })



          //delete my firebase account
          const user = auth.currentUser
          const userDeleted = await deleteUser(user)

       


          //clear localstorage
          console.log('clearing localstorage');
          //window.localStorage.removeItem('userAbility');
          //window.localStorage.removeItem('company');
          //window.localStorage.removeItem('userData');
          //route to loginscreen
          console.log('pushing to login')
          //router.push('/')
          resolve({ msg: "DELETE OK", status: true});
        }
        catch {
          console.log('error');
          reject({msg: "error somewhere", status: false});
        }
      })
    },

    DeleteFolderRecursive(folderPath) {
      //const ref = storeRef(storage, folderPath)
      //const list = await listAll(ref)
      //let filesDeleted = 0

      //for await (const fileRef of list.items) {
      //  await deleteObject(fileRef)
      //  filesDeleted++
      //}
      //for await (const folderRef of list.prefixes) {
      //  filesDeleted += await this.deleteFolderRecursive(folderRef.fullPath)
      //}
      //return filesDeleted
    },

  },
}
