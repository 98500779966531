<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form ref="changePasswordForm">
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Current Password"
                outlined
                dense
                :rules="[required]"
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="New Password"
                outlined
                dense
                hint="Make sure it's at least 8 characters."
                persistent-hint
                :rules="[required,passwordValidator]"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                dense
                :rules="[required,confirmedValidator(cPassword,newPassword)]"
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <!--<v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>-->
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <!--<v-divider></v-divider>

      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Two-factor authentication</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="primary"
            >
              {{ icons.mdiLockOpenOutline }}
            </v-icon>
          </v-avatar>
          <p class="text-base text--primary font-weight-semibold">
            Two factor authentication is not enabled yet.
          </p>
          <p class="text-sm text--primary">
            Two-factor authentication adds an additional layer of
            security to your account by requiring more than just a
            password to log in. Learn more.
          </p>
        </v-card-text>-->

        <!-- action buttons -->
        <v-card-text>
          <p class="error--text">{{errorMessages.errorText}}</p>
          <p class="success--text">{{errorMessages.succesText}}</p>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click="changePassword"
          >
            Save changes
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
  import { ref } from '@vue/composition-api'
  import { required, emailValidator, passwordValidator, alphaValidator, confirmedValidator, } from '@core/utils/validation'


  import { auth } from '@/Firebase/Firebase.service'
  import { signInWithEmailAndPassword, reauthenticateWithCredential, EmailAuthProvider, updatePassword  } from "firebase/auth";


export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')
    const changePasswordForm = ref(null)

    const errorMessages = ref([])

    const changePassword = async () => {
      console.log("asdasd")
      errorMessages.value = { errorMessages: "", succesText: ""}
      const isFormValid = changePasswordForm.value.validate()
      if (!isFormValid) return
      var userData = JSON.parse(localStorage.getItem('userData'))
      const credential = EmailAuthProvider.credential(userData.email,currentPassword.value)
      reauthenticateWithCredential(auth.currentUser, credential).then(() => {
        updatePassword(auth.currentUser, newPassword.value).then(() => {
          errorMessages.value = { succesText: "Password successfully updated"}
        }).catch((err) => {
          errorMessages.value = { errorText:  "Failed to update password"}
          console.log(err)
        })
      }).catch((err) => {
        switch (err.code) {
          case "auth/wrong-password":
            errorMessages.value = { errorText: "Your current password is incorrect" }
            break;
          default:
            errorMessages.value = { errorText: err.code }
        }
      })
      //error--text
      //success--text

      return
      //first we reauthenticate
      signInWithEmailAndPassword(auth, "m.visbech@epiito.com", currentPassword.value).then((userCredential) => {
        //when signed in






      }).catch((error) => { //sign in error
        console.log(error)
      })
    }


    return {
      changePassword,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      changePasswordForm,
      errorMessages,
      required,
      emailValidator,
      passwordValidator,
      confirmedValidator,
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
