<template>
  <v-card flat
          class="pa-3 mt-2">
    <v-card-text class="d-flex">
      <v-avatar rounded
                size="120"
                class="me-6">
        <v-img :src="accountDataLocale.avatar"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn color="primary"
               class="me-3 mt-5"
               @click="$refs.refInputEl.click()">
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <input ref="refInputEl"
               type="file"
               accept=".jpeg,.png,.jpg"
               :hidden="true"
               @change="readFile()" />

        <v-btn color="error"
               outlined
               class="mt-5"
               @click="resetImage">
          Reset
        </v-btn>
        <p class="text-sm mt-5">
          Allowed JPG or PNG. Max size of 800K
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col md="6"
                 cols="12">
            <v-text-field v-model="accountDataLocale.username"
                          label="Username"
                          dense
                          outlined></v-text-field>
          </v-col>

          <!--<v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="accountDataLocale.name"
            label="Name"
            dense
            outlined
          ></v-text-field>
        </v-col>-->
          <!--<v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="accountDataLocale.email"
            label="E-mail"
            dense
            outlined
          ></v-text-field>
        </v-col>-->
          <!--<v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="accountDataLocale.role"
            dense
            label="Role"
            outlined
          ></v-text-field>
        </v-col>-->
          <!--<v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="accountDataLocale.status"
            dense
            outlined
            label="Status"
            :items="status"
          ></v-select>
        </v-col>-->
          <!--<v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="accountDataLocale.company"
            dense
            outlined
            label="Company"
          ></v-text-field>
        </v-col>-->
          <!-- alert -->
          <!--<v-col cols="12">
          <v-alert
            color="warning"
            text
            class="mb-0"
          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  Your email is not confirmed. Please check your inbox.
                </p>
                <a
                  href="javascript:void(0)"
                  class="text-decoration-none warning--text"
                >
                  <span class="text-sm">Resend Confirmation</span>
                </a>
              </div>
            </div>
          </v-alert>
        </v-col>-->

          <v-col cols="12">
            <p v-show="this.saveStatus.fail" class="error--text">{{this.saveStatus.savemsg}}</p>
            <p v-show="this.saveStatus.succes" class="success--text">{{this.saveStatus.savemsg}}</p>
            <p v-show="this.saveStatus.saving">{{this.saveStatus.savemsg}}</p>
            <v-btn color="primary"
                   class="me-3 mt-4"
                   @click.prevent="saveChanges">
              Save changes
            </v-btn>
            <v-btn color="secondary"
                   outlined
                   class="mt-4"
                   type="reset"
                   @click.prevent="resetForm">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!--<div>
        <div>
          <p v-show="!deleteAccountToggle" @click="deleteAccountToggle = !deleteAccountToggle">To delete your account: view more</p>
        </div>



        <div v-show="deleteAccountToggle">
          <p @click="deleteAccountToggle = !deleteAccountToggle">To delete your account: view less</p>

          <h3>Before you go, keep in mind:</h3>
          <p>Account deletion is final. There will be no way to restore your account.</p>
          <p>All your projects, images etc. will be unlinked to your account.</p>





          <v-dialog v-model="DeleteAccountModal" persistent max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error"
                     class="me-3 mt-5"
                     @click="DeleteAccountModal=true">
                <span class="d-none d-sm-block">Delete account</span>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                Delete account?
              </v-card-title>
              <v-card-text>Before you go, keep in mind:</v-card-text>
              <v-card-text>Account deletion is final. There will be no way to restore your account.</v-card-text>
              <v-card-text>All your projects, images etc. will be unlinked to your account.</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error"
                       :loading="loading"
                       class="me-3 mt-5"
                       @click="DeleteAccountasdasd">
                  <span class="d-none d-sm-block">Confirm deletion</span>
                </v-btn>
                <v-btn color="primary"
                       class="me-3 mt-5"
                       @click="DeleteAccountModal=false">
                  <span class="d-none d-sm-block">Cancel</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>



        </div>
      </div>-->
    </v-card-text>

  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref, defineComponent } from '@vue/composition-api'
import { auth, db, storage } from '@/Firebase/Firebase.service'
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { collectionGroup, query, where, collection, doc, getDocs, getDoc, updateDoc, deleteDoc } from "firebase/firestore"
import { ref as storageRef, getDownloadURL, uploadBytesResumable, getStorage  } from "firebase/storage";
import router from '../../../router';
import userManagement from '../../UserManagement'

export let accountDataLocale = ref(JSON.parse(localStorage.getItem('userData')))
export let accountDataLoaded = ref(JSON.parse(localStorage.getItem('userData')))



  export default {
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']



    const resetImage = () => {
      accountDataLocale.value.avatar = JSON.parse(localStorage.getItem('userData')).avatar
    }

    const showDeleteAccountModal = () => {
      console.log("asdasd");
    }

    const resetForm = () => {
      accountDataLocale.value = JSON.parse(localStorage.getItem('userData'))
    }

    return {
      status,
      accountDataLocale,
      resetImage,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
    },

    data: function () {
      return {
        loading: false,
        DeleteAccountModal: false,
        deleteAccountToggle: false,
        dialog: false,
        file: null,
        saveStatus: {
          fail: false,
          succes: false,
          saving: false,
          savemsg: "",
          }
      }
  },

  methods: {
    async saveChanges() {
      this.saveStatus.saving = true
      this.saveStatus.fail = false
      this.saveStatus.succes = false
      this.saveStatus.savemsg = "saving.."

      console.log("set loading")
      //create promise for image upload
      var promiseAvatarImageUpload = new Promise((resolve, reject) => {
        //no need to upload new avatar image if it hasnt changed
        if (this.$refs.refInputEl.files[0] == null) {
          resolve("ok");
        }
        else {
          const metadata = {
            contentType: 'image/png'
          };

          const file = this.$refs.refInputEl.files[0]
          const _storageRef = storageRef(storage, `user/${accountDataLoaded.value.id}/avatar.png`);
          const uploadTask = uploadBytesResumable(_storageRef, file);

          uploadTask.on('state_changed',
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
                case 'success':
                  console.log('Upload completed')
              }
            },
            (error) => {
              console.log(error)
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;
                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
              reject(error.code)
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                //we also need to update the avatarImage field on user and memberships
                //user/3Vc1rls5RZPIYlAsYvGSyVSO7tK2/avatar.png

                //user/userid/username has to be changed
                var userDocRef = doc(db, "users", accountDataLoaded.value.id)
                updateDoc(userDocRef, {
                  avatarImage: `/user/${accountDataLocale.value.id}/avatar.png`
                }).then(() => {
                  console.log("update user doc complete")

                  //create membership query and get all memberships for the current logged in account
                  const userDocRef = doc(db, "users", accountDataLoaded.value.id)
                  const membershipsQuery = query(collectionGroup(db, 'memberships'), where('user', '==', userDocRef))
                  console.log("getdocs")
                  getDocs(membershipsQuery).then((membershipDocs) => {
                    console.log(membershipDocs)
                    //create promise list
                    const promises = []
                    membershipDocs.forEach(membershipDoc => {
                      console.log("for")
                      var promise = new Promise((res, rej) => {
                        console.log("update doc")
                        //update membership document with new username
                        updateDoc(membershipDoc.ref, {
                          avatarImage: `/user/${accountDataLocale.value.id}/avatar.png`
                        }).then(() => {
                          //update ok
                          console.log("ok")
                          res("ok")
                        }).catch((err) => {
                          //update failed
                          console.log(err)
                          rej(err)
                        })
                      })
                      promises.push(promise)
                    })
                    //if update of all membership docs is complete, we need to update local storage
                    Promise.all(promises).then((values) => {
                      //update local storage userData
                      accountDataLocale.value.avatar = downloadURL
                      localStorage.setItem('userData', JSON.stringify(accountDataLocale.value))
                      accountDataLoaded.value = JSON.parse(JSON.stringify(accountDataLocale.value));
                      //update companyAndRolesData
                      var companyandRolesTable = JSON.parse(localStorage.getItem('companiesAndRoles'))
                      console.log(companyandRolesTable)
                      companyandRolesTable.forEach((companyandRole) => {
                        companyandRole.membership.avatarImage = `/user/${accountDataLocale.value.id}/avatar.png`
                      })
                      console.log("all ok")
                      resolve("ok")
                    }).catch((err) => { //check for errirs
                      console.log("errors happned" + err)

                      this.saveStatus.saving = false
                      this.saveStatus.fail = true
                      this.saveStatus.savemsg = "something bad happened.. please try again later"

                      reject(err)
                    })
                  })
                })
              })
            }
          )
        }
      })

      //create promise for username save
      var promiseUsernameSave = new Promise((resolve, reject) => {
        //no need to update username if they are the same
        if (accountDataLocale.value.username == accountDataLoaded.value.username) {
          resolve("ok")
        }
        else {
          //user/userid/username has to be changed
          var userDocRef = doc(db, "users", accountDataLoaded.value.id)
          updateDoc(userDocRef, {
            username: accountDataLocale.value.username
          }).then(() => {
            console.log("update user doc complete")

            //create membership query and get all memberships for the current logged in account
            const userDocRef = doc(db, "users", accountDataLoaded.value.id)
            const membershipsQuery = query(collectionGroup(db, 'memberships'), where('user', '==', userDocRef))
            console.log("getdocs")
            getDocs(membershipsQuery).then((membershipDocs) => {
              console.log(membershipDocs)
              //create promise list
              const promises = []
              membershipDocs.forEach(membershipDoc => {
                console.log("for")
                var promise = new Promise((res, rej) => {
                  console.log("update doc")
                  //update membership document with new username
                  updateDoc(membershipDoc.ref, {
                    username: accountDataLocale.value.username
                  }).then(() => {
                    //update ok
                    console.log("ok")
                    res("ok")
                  }).catch((err) => {
                    //update failed
                    console.log(err)
                    rej(err)
                  })
                })
                promises.push(promise)
              })
              //if update of all membership docs is complete
              Promise.all(promises).then(companiesAndRolesCollection => {

                accountDataLocale.value.fullName = accountDataLocale.value.username
                //update local storage userData
                localStorage.setItem('userData', JSON.stringify(accountDataLocale.value))
                accountDataLoaded.value = JSON.parse(JSON.stringify(accountDataLocale.value));
                //update companyAndRolesData
                var companyandRolesTable = JSON.parse(localStorage.getItem('companiesAndRoles'))
                console.log(companyandRolesTable)
                companyandRolesTable.forEach((companyandRole) => {
                  companyandRole.membership.username = accountDataLocale.value.username
                })


                console.log("all ok")
                resolve("ok")
              }).catch((err) => { //check for errirs
                console.log("errors happned" + err)

                this.saveStatus.saving = false
                this.saveStatus.fail = true
                this.saveStatus.savemsg = "something bad happened.. please try again later"

                reject(err)
              })
            })
          })
        }
      })
      //when everything is complete
      Promise.all([promiseAvatarImageUpload, promiseUsernameSave]).then((values) => {
        //need to update local storage with new values, these values are gotten from the promises
        console.log(values)



        console.log("all completed")
        //show messege when completed
        this.saveStatus.succes = true
        this.saveStatus.saving = false
        this.saveStatus.fail = false
        this.saveStatus.savemsg = "Save completed, it might take some time for some changes to take effect"
      }).catch((reason) => {
        console.log("something failed")
        this.saveStatus.saving = false
        this.saveStatus.fail = true
        this.saveStatus.savemsg = "something bad happened.. please try again later"
      })
    },


    async DeleteAccountasdasd() {
      this.loading = true
      var accountData = ref(JSON.parse(localStorage.getItem('userData')))
      var promise = await userManagement.actions.DeleteAccount(accountData.value.id)
      console.log(promise);
      this.loading = false
    },


    //change avatar image to the selected file
    readFile() {
      accountDataLocale.value.avatar = URL.createObjectURL(this.$refs.refInputEl.files[0]);
    },



    //const refInputEl = ref(null)



    //const readFile = async () => {
    //  console.log(refInputEl.files)
    //  console.log("asdasd")
    //  var example = this.refInputEl.files[0];
    //  console.log(example)
    //}



    //const saveChanges = async () => {

    //  //also need to load correct data into fields...

    //  console.log("asdasd")
    //  //const usersQuery = query(collectionGroup(db, 'users'), where('email', '==', userData.email));

    //  //get all memberships need to change username and photo
    //  //get own doc change username and photo

    //  //upload new photo to storage




    //}



  },

}
</script>

<style scoped>
  .modal-mask {
    position:fixed;
    z-index:9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display:table;
    transition: opacity .3s ease;
  }
  .modal-wrapper{
    display: table-cell;
    vertical-align: middle;
  }



</style>
